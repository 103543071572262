/* SUIT */
@font-face {
	font-family: 'SUIT';
	font-weight: 400;
	src: local('SUIT Regular'), url('../font/SUIT-Regular.otf') format('otf'),
		local('SUIT Regular'), url('../font/SUIT-Regular.ttf') format('ttf'),
		local('SUIT Regular'), url('../font/SUIT-Regular.woff2') format('woff2');
}
@font-face {
	font-family: 'SUIT';
	font-weight: 500;
	src: local('SUIT Medium'), url('../font/SUIT-Medium.otf') format('otf'),
		local('SUIT Regular'), url('../font/SUIT-Regular.ttf') format('ttf'),
		local('SUIT Regular'), url('../font/SUIT-Regular.woff2') format('woff2');
}
@font-face {
	font-family: 'SUIT';
	font-weight: 700;
	src: local('SUIT Bold'), url('../font/SUIT-Bold.otf') format('otf'),
		local('SUIT Regular'), url('../font/SUIT-Regular.ttf') format('ttf'),
		local('SUIT Regular'), url('../font/SUIT-Regular.woff2') format('woff2');
}