/***************************/
/** layout ui css **/
/***************************/
html{min-width: 1640px;}
body { -webkit-text-size-adjust: 100%; }
p{line-height: 1.6em;}
/* header */
/* header */
#header{position: fixed; top:0; left:0; width:100%; height: 85px; padding: 25px 142px; display: grid; grid-template-columns: 200px 1fr 28px; gap:50px; z-index: 999; transition: all .3s; align-items: center; min-width: 1640px;}
#header h1 > a > img{width:200px;}
#topMenuView{justify-self: end;}
#header ul.topMenu{display: grid; grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; align-items: center; width:1000px;}
#header ul.topMenu > li > a{display: block; height: 100%; font-size: 18px; text-align: center; line-height: 34px; color:#fff; font-weight: 600;}
#header .allMenuView {display: none; position: fixed; top:85px; left:0; background-color: #fff; z-index: 998; box-shadow: rgb(0 0 0 / 4%) 0px 3px 5px; transition: all .3s;
	 background-image: url(../../../public/images/common/allmenuBg.png); background-repeat: no-repeat; background-position:90% 50%; width:100%;  min-width: 1640px; padding:20px 220px 20px 142px; border-top: 1px solid #ebebeb;}
#header .allMenu{width:1000px; position: relative; right:0;; display: grid; grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; margin: 0 0 0 auto;}
#header .allMenu > li{text-align: center;}
#header .allMenu > li > a{font-size: 0;}
#header .allMenu > li li{margin-bottom: 10px; }
#header .allMenu > li li > a{display: block; height: 100%; padding: 10px; font-size: 16px; color:#888;}
#header .topBtnmenu{display: block;width:28px; height: 28px; padding: 4px;}
#header .topBtnmenu2{display: block;width:28px; height: 28px; padding: 4px;}

/* header:hover */
#header:hover ul.topMenu > li > a{color:#222;}
#header:hover, #header.topFix{background-color: #fff;}
#header:hover ul.topMenu > li:hover > a{color:#497375; font-weight: 700;}
#header .allMenu li li:hover > a{color:#497375; font-weight: 600;}

/* #header:hover .allMenuView {display: block; width: 100%;} */
#header:hover h1 > a > img, .topFix > h1 > a > img{content: url(../../../public/images/common/logo.png);}
#header:hover .topBtnmenu > img, .topFix > .topBtnmenu > img{content: url(../../../public/images/common/menu_b.png);}
#header .topBtnmenu > .imgClick{content: url(../../../public/images/icon/ic_menuClose.png) !important;}
#header.topFix ul.topMenu>li>a{color:#222;}
/* footer */
#footer{padding: 50px 142px; background-color: #27282C;}
#footer .ftTop{position: relative;}
#footer .ftTop::after{display: block; content: ''; position: absolute; top: 60px; border-bottom: 1px solid #fff; left: 0; width:100%; height: 1px;}
#footer .ftTop{ margin-bottom: 46px;}
#footer .ftTop h3{width:200px;}
#footer .ftTop > ul{display: grid; grid-template-columns: 2fr 1fr 1.2fr 1fr 1fr 1fr 0.5fr; gap:50px;}
#footer .ftTop > ul.sitemapTitle a{color:#fff; font-size: 18px;}
#footer .ftTop > .sitemapSub ul:last-child li img{width:24px; margin-left: 6px;}
#footer .ftTop > .sitemapSub ul li{margin-bottom: 20px;}
#footer .ftTop > .sitemapSub li:last-child{margin-bottom: 0;}
#footer .ftTop > .sitemapSub li a{font-size: 14px; color:#fff;}
#footer .ftTop > .sitemapSub li a:hover, .footer .ftTop > ul.sitemapTitle a:hover{color:#aaa;}
#footer .ftTop > .inquire{background-color: #35373E; padding: 30px;}
#footer .ftTop > .inquire > p:first-child{color:#fff; font-size: 20px; margin-bottom: 20px; font-weight: 500;}
#footer .ftTop > .inquire a, .footer .ftTop .ftBottom > .ftbLeft p{color:#fff; font-weight: 500; font-size: 16px; margin-bottom: 10px;}
#footer .ftTop > .inquire span, #footer .ftTop > .inquire p, .footer .ftTop > .inquire p{color:#fff; margin-right: 10px; font-size: 16px;}
#footer .ftInfo > p{color:#aaa; margin: 10px 0;}
#footer .ftInfo > ul li{display: inline-block;}
#footer .ftInfo > ul li:first-child p{font-size: 15px; padding-right: 26px; color:#fff; font-weight: 600;}
#footer .ftInfo > ul li > a{color:#fff; margin-right: 20px;}
#footer .ftInfo p > .empty{display: block; height: 8px; line-height: 26px; line-height: 26px;}
#footer .ftInfo address{font-size: 12px; letter-spacing: 6px; font-weight: 400; color:#777;}

/* sub */
.contens{margin-top: 85px; margin-bottom: 50px;}
.subtitleBox{width:100%; height: 240px; background-color: #aaa; padding: 53px 280px; position: relative; max-width:1920px; margin:0 auto;}
.subtitleBox > .stTitle{font-size: 42px; font-weight: 600; margin-bottom: 10px; color:#fff;}
.subtitleBox > .stStitle{font-size: 20px; color:#fff;}
.subCont{padding: 50px 280px; max-width: 1920px; margin: 0 auto;}
/* subTab */
.subTab > ul{grid-template-columns: 1fr 1fr; max-width: 1920px; margin:0 auto;}
.subTab{margin-bottom: 80px;}
.subTab > ul{display: grid; gap:20px; width: 500px; margin: 0 auto;}
.subTab > ul > li{width:100%; text-align: center;}
.subTab > ul > li > a{display: block; border-radius: 50px; border:1px solid #ccc; text-align: center; color:#999; font-size: 16px; padding: 14px;}
.subTab > ul > li.active > a{background-color: #497375; color:#fff; font-weight: 600; }

/* 404page */
.error404{background: url(../../../public/images/errorBg.png)no-repeat; height: 100vh; display: flex; align-items: center;}
.error404 > div{position: relative; left:200px}
.error404 > div > h1{font-size: 300px; color:#F2F5F5;}
.error404 > div > p:nth-child(2){font-size: 30px;}
.error404 > div > p:nth-child(3){color:#999; font-size: 18px; margin:40px 0 20px 0;}
.error404 > div > a{color:#497375; display: inline-block; font-size: 16px;}
.error404 > div > a > img{margin-left: 10px; width:40px; padding: 4px 0;}

/* ****************** LAYOUT ********************** */
@media all and (max-width:800px){
	
    .btn, a.btn{padding: 14px; width:100%;}
	#btnPageTop{right:25px; bottom:100px;}
	

	html{min-width: 320px;}
	#header{min-width: 320px;  padding: 20px 20px; height: 65px; display: flex; justify-content: space-between; gap:0;}
	#header .allMenu{grid-template-columns: 1fr; gap:50px; overflow-y: scroll; padding: 80px 50px 100px 50px; height: 100vh; min-width: 340px; margin: 0; width:100vw;}
    #header .allMenu > li{position: relative; font-size: 20px; font-weight: 600;}
    #header .allMenu > li::before{display: block; position: absolute; top:-20px; left:0; width:100%; color:#497375}
    #header .allMenu > .closeBtn::after{display: block; content: ''; position: absolute; top:-20px; right:0; width:14px; height: 8px; background: url(../../../public/images/icon/ic_down.png)no-repeat; background-size: 100%;}
	#header .allMenu > .openBtn ::after {display: block; content: ''; position: absolute; top:-20px; right:0; width:14px; height: 8px; background: url(../../../public/images/icon/ic_up.png)no-repeat; background-size: 100%;}
    #header .allMenu li li:hover > a{font-weight: 400;}
	#header .allMenu > li .hidden {display : none;}
    /* #header .allMenu > li:first-child::before{content: '회사소개';}
    #header .allMenu > li:nth-child(2)::before{content: '상담신청';}
    #header .allMenu > li:nth-child(3)::before{content: '채용안내';}
    #header .allMenu > li:nth-child(4)::before{content: '유튜브 소개';} */
    #header .allMenu > li:first-child::before{content: '매물 및 실거래 정보';}
    #header .allMenu > li:nth-child(2)::before{content: '회사소개';}
    #header .allMenu > li:nth-child(3)::before{content: '투자정보';}
    #header .allMenu > li:nth-child(4)::before{content: '상담신청';}
    #header .allMenu > li:nth-child(5)::before{content: '채용안내';}
    #header .allMenu > li:nth-child(6)::before{content: '컨텐츠';}
	#header .topMenu{display: none !important;}
	#header .allMenuView{top:65px; padding:0;}
	#header h1 > a > img{width:160px;}
	#header .allMenu > li{display: block;}
	
	.scrollNav{display: none;}
	.scrollNav > li{display: inline-block; margin-bottom: 0;}
	.scrollNav > li:last-child{margin-right: 0; }
	.scrollNav > li > a{font-size: 18px; margin-bottom: 0;}

    .indexVisual .ivCont .mvBox .mvTitle{width:100%; padding: 20px; top:40%;}
    .indexVisual .ivCont .mvBox .mvTitle > p:nth-child(2), .indexVisual .ivCont .mvBox .mvTitle > p:nth-child(2) > span{font-size: 32px;}
	.indexVisual .ivCont .mvBox .mvTitle > p:first-child{font-size: 20px; margin-bottom: 10px;}
	.indexVisual .ivCont > .slideController{left:50%;}
	.indexVisual .ivCont > .slideController > span.slideCountAll{margin-right: 0;}
	.indexVisual .ivCont > .slideController button{display: none;}

	.indexVisual .main_visual_box .mvTitle{width:100%;}
	.indexVisual .main_visual_box .mvTitle > p:first-child{font-size: 18px; margin-bottom: 10px;}
	.indexVisual .main_visual_box .mvTitle > p:nth-child(2), .indexVisual .main_visual_box .mvTitle > p:nth-child(2) > span{font-size: 34px;}

	.subTab > ul{grid-template-columns: 1fr; width:auto;}

	#contens .subCont{padding: 40px 20px !important;}
	#contens .subtitleBox{padding: 20px 0; height: auto;}
	#contens .subtitleBox > .subNav{bottom: -20px; padding-top: 8px;}
	#contens .subtitleBox > .stTitle{font-size: 24px; padding-left: 40px; display: inline-block;}
	#contens .subtitleBox > .stStitle{font-size: 18px; padding-left: 40px; display: inline-block;}
	
	#footer{padding: 0 0 20px 0;}
	#footer .ftTop{display: none;}
	#footer .ftInfo{text-align: center;}
	#footer .ftInfo > p{margin-top: 10px;}
	#footer .ftInfo p > .empty{display: block; height: 10px; line-height: 10px;}
	#footer .ftInfo address{letter-spacing: normal;}
	#footer .ftInfo > ul{padding: 20px; border-bottom: 1px solid #888; display: grid; grid-template-columns: 1fr; gap:10px;}
	#footer .ftInfo > ul li > a{color:#aaa;}
	#footer .ftInfo > ul li:first-child p{padding-right: 0;}

	/* 404 */
	.error404 > div{left:50%; transform: translate(-50%); text-align: center;}
	.error404 > div > h1{font-size: 100px;}
	.error404 > div > p:nth-child(2){font-size: 20px;}
}