.paging-div {padding: 10px; display: table; margin-left: auto; margin-right: auto; text-align: center;}
.paging-div ul{display: block;}
.pagination li{display: inline-block;}
.pagination li button {width:32px; height: 32px; background-color: #fff; border: 1px solid #eee; margin-right: 8px; border-radius: 8px; align-items: center; font-size: 12px; line-height: 14px; letter-spacing: 0.25px;}
.pagination li:last-child > button{margin: 0 !important;}
.pagination li button img {height: 16px; width: 16px; }
.pagination .disabled {cursor: not-allowed; background-color: #fff;}
.pagination button:hover {background: #eee;}
.pagination li>.active, .pagination li>.active:focus, .pagination li>.active:hover {color: #fff; cursor: default; background-color: #497375; border-color: #497375; border-radius: 8px;}
button {cursor: pointer;}
.first > button, .prev > button, .next > button, .last > button{padding: 0 !important;}