/*******************************************/
/* Default Style */
/******************************************/
* { margin: 0; padding: 0; box-sizing: border-box; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; font-size: 14px; font-family: 'SUIT-Regular', sans-serif; color: #000; }
@font-face {
    font-family: 'SUIT-Regular';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
html, body, form { width: 100%; height: 100%; scroll-behavior: smooth;}
/*body { }*/
header, footer, section, aside, nav, article { display: block; }
input, select, div, textarea { vertical-align: middle; }
img { vertical-align: top; }
img, fieldset { border: none;  }
ul, ol, dl { list-style: none; }
strong { font-weight: 800; }
em { font-style: oblique; }
img{width:100%;}
/* Link Style */
a { text-decoration: none; color: #444;}
a:hover { text-decoration: none; }
/* From Style */
textarea { padding: 8px; }
input, select, textarea { border: none; border-bottom: 1px solid #ccc; width: 100%; color: #444; padding: 4px 8px;}
input[type=image] { width: auto; border: none; }
select { padding: 4px 8px; padding-left: 4px; }
input[type=checkbox], input[type=radio] { width: 18px; height: 18px; border: 1px solid #e4e4e4; }
input[type=text], input[type=password] { padding: 6px 8px; }
input[type=text]:focus, input[type=password]:focus, textarea:focus, select:focus {border-bottom: 1px solid #2B9180; outline: none; }
input[type=text]::-ms-clear { display:none; }
input[type=checkbox], input[type=radio], input[type=button], label { cursor: pointer; }
input[disabled], select[disabled] { background-color: #f2f2f2; cursor: not-allowed; }
input[readonly] { background-color: #f2f2f2; cursor: not-allowed; }
input[readonly].txtRight, input.txtRight, input[readonly].txtR, input.txtR, .txtR { text-align: right; }
input[readonly].txtC, input.txtC, .txtC { text-align: center !important; }
input[readonly].txtL, input.txtL, .txtL { text-align: left !important; }
input::-webkit-input-placeholder { color: #eee; }
textarea::-webkit-input-placeholder { color: #ccc; }
input:-moz-placeholder { color: #ccc; }
textarea:-moz-placeholder { color: #ccc; }
input::-moz-placeholder { color: #ccc; }
textarea::-moz-placeholder { color: #ccc; }
input:-ms-input-placeholder { color: #ccc; }
textarea:-ms-input-placeholder { color: #ccc; }
input:-mos-input-placeholder { color: #ccc; }
input::placeholder { color: #ccc; } /* IE10 이상을 위한 css */
.required, input.required, select.required, textarea.required {border: 2px solid #1e8fbe;}

/* Table Style */
table { border-collapse: collapse; border-spacing: 0; width: 100%; text-align: center; }
table td img { vertical-align: middle; }
th { padding: 10px; white-space: nowrap; font-weight: normal;}
table th > span{color:#999;font-size: 12px; font-weight: normal;}
td { padding: 10px; word-break:break-all; }
td.nowrap { white-space:nowrap; }
th.thL, td.tdL { text-align: left; }
th.thC, td.tdC { text-align: center; }
th.thR, td.tdR { text-align: right; }
th.thChk { padding: 0;}
/* font size */
.ftSize10 { font-size: 10px; }
.ftSize11 { font-size: 11px; }
.ftSize12 { font-size: 12px; }
.ftSize13 { font-size: 13px; }
.ftSize14 { font-size: 14px; }
.ftSize16 { font-size: 16px; }
.ftSize18 { font-size: 18px; }
.ftSize20 { font-size: 20px; }
.ftSize24 { font-size: 24px; }
.ftSize28 { font-size: 28px; }
.ftNormal { font-weight: normal; }
.ftMedium { font-weight: 600; }
.ftBold { font-weight: 700 !important; }
/*color*/im
.colGray { color: #666; }
.colGray2 { color: #999; }
.colGray3 { color: #ccc; }
.colBlue { color: #0091ea!important; }
.colBlue2 { color: #0266c1; }
.colWhite { color: #FFFFFF; }
.colRed { color: #FF5B5B !important;}
.colGreen { color: #497375; }
.colGreen2 { color: #1fd11f; }
.colOrange { color: #f2cc15; }
.colOrange2 { color: #f9a825; }
.colBlack { color: #333; }
.colPink { color: #D66C7B; }
.colPurple { color: #7D2A8B; }
.colYellow { color: #FFC400 !important; }
.colWhite { color: #FFFFFF; }
.colBrown { color: #b75013; }
.colSky { color: #02a6a6; }
.colNavy { color: #074199; }
.colPoint1 { color: #497375 !important; }
.colPoint2 { color: #2B9180; }
.colPoint3 { color: #3CC7B0; }
/* margin */
.mg0 { margin: 0 !important; }
.mgT3 { margin-top: 3px; }
.mgT5 { margin-top: 5px; }
.mgT10 { margin-top: 10px; }
.mgT20 { margin-top: 20px; }
.mgT30 { margin-top: 30px; }
.mgT40 { margin-top: 40px; }
.mgT50 { margin-top: 50px; }
.mgB3 { margin-bottom: 3px; }
.mgB5 { margin-bottom: 5px; }
.mgB10 { margin-bottom: 10px; }
.mgB20 { margin-bottom: 20px; }
.mgB30 { margin-bottom: 30px; }
.mgB40 { margin-bottom: 40px; }
.mgB50 { margin-bottom: 50px; }
.mgTB5 { margin-top: 5px; margin-bottom: 5px; }
.mgTB10 { margin-top: 10px; margin-bottom: 10px; }
.mgTB20 { margin-top: 20px; margin-bottom: 20px; }
.mgRL10 { margin-right: 10px; margin-left: 10px;}
.mgRL20 { margin-right: 20px; margin-left: 20px;}
.mgR5 { margin-right: 5px; }
.mgR10 { margin-right: 10px; }
.mgR20 { margin-right: 20px; }
.mgL5 { margin-left: 5px; }
.mgL10 { margin-left: 10px; }
.mgL20 { margin-left: 20px; }
.mg10 { margin: 10px; }
/* padding */
.pdT3 { padding-top: 3px; }
.pdT5 { padding-top: 5px; }
.pdT10 { padding-top: 10px; }
.pdT20 { padding-top: 20px; }
.pdT30 { padding-top: 30px; }
.pdB5 { padding-bottom: 5px; }
.pdB10 { padding-bottom: 10px;}
.pdTB10 { padding-top: 10px; padding-bottom: 10px; }
.pdTB20 { padding-top: 20px; padding-bottom: 20px; }
.pdR20 { padding-right: 20px; }
.pdL5 { padding-left: 5px; }
.pdL10 { padding-left: 10px; }
.pdL20 { padding-left: 20px; }
.pd10 { padding: 10px; }
/* ellipsis */
.text-ellipsis { display: table; table-layout: fixed; width: 100%; white-space: nowrap; }
.text-ellipsis > * { display: table-cell; overflow: hidden; text-overflow: ellipsis; }
.text-ellipsis.new > a > div { width: auto; }
/* float */
.floatL{float:left;}
.floatR{float:right;}
.overBox{overflow: hidden;}
/* img vertical */
img.va-md { vertical-align: middle; }
img.va-bt { vertical-align: bottom; }
img.va-tp { vertical-align: top; }
/* button */
.btn > img{vertical-align: inherit;}
.btn, a.btn { display: inline-block; width: auto; cursor: pointer; padding: 14px 120px; vertical-align: middle; font-size: 16px; text-align: center;}
.btn2, a.btn2 { width:49%; margin-right: 1%; text-align: center;}
.btn2, a.btn2:last-child { margin-right: 0%;}
.btn100, a.btn100 {width:100%; text-align: center; padding: 10px;}
.btnS, a.btnS {text-align: center; padding: 4px 7px;}
.btnPoint1, a.btnPoint1 { background-color: #497375; border:1px solid #497375; color: #fff; }
.btnPointLine1, a.btnPointLine1 { background-color: #fff; border:1px solid #497375; color: #497375; }
.btnPoint2, a.btnPoint2 { background-color: #2B9180; border:1px solid #2B9180; color: #fff; }
.btnPointLine2, a.btnPointLine2 { background-color: #fff; border:1px solid #2B9180; color: #2B9180; }
.btnPoint3, a.btnPoint3 { background-color: #3CC7B0; border:1px solid #3CC7B0; color: #fff; }
.btnPointLine3, a.btnPointLine3 { background-color: #fff; border:1px solid #3CC7B0; color: #3CC7B0 !important; }
.btnWhite, a.btnWhite { background-color: #fff; border: 1px solid #ddd; color: #707070; }
.btnNavy, a.btnNavy { background-color: #002157; border-color: #002157; color:#fff;}
.btnRed, a.btnRed { background-color: #FF5B5B; border:1px solid #FF5B5B; color:#fff;}
.btnRedLine, a.btnRedLine { background-color: #fff; border:1px solid #FF5B5B; color: #FF5B5B; }
.btnGreen, a.btnGreen { background-color: #4caf50; border-color: #4caf50; color:#fff;}
.btnGreenLine, a.btnGreenLine { background-color: #fff; border:1px solid #4caf50; color:#4caf50;}
.btnBlue, a.btnBlue { background-color: #0091ea; border:1px solid #0091ea; color:#fff;}
.btnOrange, a.btnOrange { background-color: #E79E26; border:1px solid #E79E26; color:#fff;}
.btnYellow, a.btnYellow { background-color: #FFC400; border:1px solid #FFC400; color:#000;}
.btnGray, a.btnGray { background-color: #f2f2f2; border:1px solid #ccc; color:#707070;}
.btnBlueGreen, a.btnBlueGreen { background-color: #004d48; border-color: #004d48; color:#fff;}
.btnCenter { display: block; text-align: center; }
.btnRight { display: block; text-align: right; }
.btnPointer { cursor: pointer; }
/* radius Box */
.radiusBox{padding: 4px 10px; border-radius: 15px;}
.radiusBoxS{padding: 2px 4px; border-radius: 10px;}
/* border */
.borderAll{border: 1px solid #ccc;}
.borderR{border-right: 1px solid #ccc;}
.borderL{border-left: 1px solid #ccc;}
.borderT{border-top: 1px solid #ccc !important;}
.borderB{border-bottom: 1px solid #ccc;}
.borderNone{border: 0 !important;}